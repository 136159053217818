import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { login, verifyMail, csrfToken, logout, register, verifyUser } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import axios from 'axios'
import defaultTheme from 'configs/theme.config'
import { userRedux } from 'constants/user.constant'

function useAuth() {

	const dispatch = useDispatch()

	const navigate = useNavigate()

	const query = useQuery()

	const { token, signedIn } = useSelector((state) => state.auth.session)

	const redirectToPath = async (path) => {

		await new Promise((resolve) => {
			setTimeout(() => {
				navigate(path);
				resolve();
			}, 2000);
		});
	};

	const getCsrf = async () => {
		const csrfAxios = axios.create({
			baseURL: appConfig.apiBase,
			withCredentials: true,
			headers: {
				'X-Requested-With': 'XMLHttpRequest'
			}
		});
		await csrfAxios.get('/sanctum/csrf-cookie');
	}

	const signIn = async (values) => {
		try {
			await getCsrf()
			const resp = await login(values.email, values.password);
			if (resp.data) {
				const data = resp.data.data;
				if (!data.token) {
					return {
						status: 'failed',
						message: resp.data.message
					}
				}
				const authToken = data.token
				dispatch(onSignInSuccess(authToken))
				if (data.user) {
					const user = { ...userRedux }
					user.FirstName = data.user.firstName
					user.LastName = data.user.lastName
					user.ProfilePicUrl = data.user.profilePictureUrl
					user.Email = data.user.email
					user.authority = ['USER']
					dispatch(setUser(user))
				}
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				await redirectToPath(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath)

				return {
					status: 'success',
					message: ''
				}
			}

		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const signUp = async (values) => {
		try {
			values.coreSettings = JSON.stringify(defaultTheme);
			const resp = await register(values.firstName, values.lastName, values.email, values.password, values.coreSettings)
			if (resp.data) {
				const redirectUrl = query.get(REDIRECT_URL_KEY)
				navigate('/sign-in?message=verify')
				return {
					status: 'success',
					message: ''
				}
			}
		} catch (errors) {
			return {
				status: 'failed',
				message: errors?.response?.data?.message || errors.toString()
			}
		}
	}

	const verifyMail = async (token) => {
		try {
			const resp = await verifyUser(token)
			if (resp.data) {
				return {
					status: resp.data.status,
				}
			}
		} catch (errors) {
			return {
				status: 'failed'
			}
		}
	}

	const handleSignOut = () => {
		dispatch(onSignOutSuccess())
		dispatch(setUser(userRedux))
		navigate(appConfig.unAuthenticatedEntryPath)
	}

	const signOut = async () => {
		await logout()
		handleSignOut()
	}

	return {
		authenticated: token && signedIn,
		signIn,
		signUp,
		signOut,
		verifyMail
	}
}



export default useAuth