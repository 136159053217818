// const appConfig = {
//     base: 'http://127.0.0.1:3000',
//     apiBase: 'http://127.0.0.1:8000',
//     apiPrefix: `http://127.0.0.1:8000/api`,
//     profileImages: 'http://127.0.0.1:8000/resource/user/profile',
//     authenticatedEntryPath: '/dashboard',
//     unAuthenticatedEntryPath: '/sign-in',
//     tourPath: '/',
//     enableMock: false
// }

const appConfig = {
    base: 'https://app.trackify.click',
    apiBase: 'https://api.trackify.click',
    apiPrefix: `https://api.trackify.click/api`,
    profileImages: 'https://app.trackify.click/resource/user/profile',
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false
}

export default appConfig