import { THEME_ENUM } from 'constants/theme.constant'
// import { useEffect } from 'react';
// import { getUserTheme } from 'services/ConfigService';

// const loadTheme = async () => {
//     const res = getUserTheme()
//     return res
// }
// const userTheme = loadTheme()
// useEffect(() => {
//     loadTheme()
// })

export const themeConfig = {
    themeColor: 'purple',
    direction: 'ltr',
    mode: 'light',
    locale: 'en',
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: 'light',
    layout: {
        type: 'modern',
        sideNavCollapse: false,
    },
}

export default themeConfig;
