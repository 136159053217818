import * as Yup from 'yup'

export const userFieldValidation = Yup.object().shape({
    gender: Yup.string(),
    firstName: Yup.string().required('Vorname eingeben').max(200, 'Zu langer Name'),
    lastName: Yup.string().required('Nachname eingeben').max(200, 'Zu langer Name'),
    email: Yup.string().email('Gültige Email eintragen').required('Email eingeben'),
    // PostalCode: Yup.string().matches(/^(\d{5})?$/, 'Keine gültige Postleitzahl').notRequired().nullable(),
})

export const userDefault = {
    title: '',
    firstName: '',
    lastName: '',
    gender: '-',
    email: '',
    dateOfBirth: '',
    phoneNumber: '',
    postalCode: '',
    city: '',
    street: ''
}

export const userRedux = {
    ProfilePicUrl: '',
    FirstName: '',
    LastName: '',
    authority: [],
    Email: '',
    UserId: 0,
    set: function (ProfilePicUrl, FirstName, LastName, authority, Email, UserId = 0) {
        this.ProfilePicUrl = ProfilePicUrl;
        this.FirstName = FirstName;
        this.LastName = LastName;
        this.authority = authority;
        this.Email = Email;
        this.UserId = UserId;
    },
    unset: function () {
        return userRedux;
    }
}

export const genderOptions = [
    { value: '-', label: 'keine Angabe' },
    { value: 'w', label: 'weiblich' },
    { value: 'm', label: 'männlich' },
    { value: 'd', label: 'divers' },
]

export const titleOptions = [
    { value: 'Dr.', label: 'Dr.' },
    { value: 'Prof. Dr.', label: 'Prof. Dr.' }
]