import ApiService from './ApiService'

export async function login(email, password) {
    const data = { email: email, password: password }
    return ApiService.fetchData({
        url: '/login',
        method: 'post',
        data
    })
}

export async function logout() {
    return ApiService.fetchData({
        url: '/logout',
        method: 'post',
    })
}


export async function register(firstName, lastName, email, password, coreSettings = null) {
    const data = { firstName: firstName, lastName: lastName, email: email, password: password, coreSettings: coreSettings }
    return ApiService.fetchData({
        url: '/signup',
        method: 'post',
        data
    })
}


export async function forgotPassword(email) {
    const data = { email: email }
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data
    })
}

export async function resetPassword(token, password, passwordConfirm, code) {
    const data = { token: token, password: password, confirmPassword: passwordConfirm, code: code }
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data
    })
}

export async function sendVerifyMail(email) {
    const data = { email: email }
    return ApiService.fetchData({
        url: '/send-verify-email',
        method: 'post',
        data
    })
}

export async function verifyUser(token) {
    const data = { verifyToken: token }
    return ApiService.fetchData({
        url: '/verify-email',
        method: 'post',
        data
    })
}

export async function csrfToken() {
    return ApiService.fetchData({
        url: '/sanctum/csrf-cookie',
        method: 'get'
    })
}
