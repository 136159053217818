import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { useBeforeunload } from 'react-beforeunload'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './store'
import Theme from 'components/template/Theme'
import Layout from 'components/layout'
import history from './history'
import mockServer from './mock'
import appConfig from 'configs/app.config'
import './locales'
import { updateOnlineStatus } from 'services/UserService'

const environment = process.env.NODE_ENV
/**
 * Set enableMock(Default false) to true at configs/app.config.js 
 * If you wish to enable mock api
 */
if (environment !== 'production' && appConfig.enableMock) {
	mockServer({ environment })
}

function App() {

	// useEffect(() => {
	// 	const setStatus = async () => {
	// 		const res = await updateOnlineStatus(1)
	// 	}
	// 	setStatus()
	// }, []);

	// useBeforeunload(() => {
	// 	updateOnlineStatus(0);
	// });

	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<BrowserRouter history={history}>
					<Theme>
						<Layout />
					</Theme>
				</BrowserRouter >
			</PersistGate>
		</Provider>
	)
}

export default App
