import BaseService from './axios/BaseService'
import FormService from './axios/FormService'

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    },
    sendForm(param) {
        return new Promise((resolve, reject) => {
            FormService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }

}

export default ApiService