import ApiService from "./ApiService"

export async function getUserData() {
    return ApiService.fetchData({
        url: '/users',
        method: 'get'
    })
}
/**
 * parameter data need to be FormData type with values:
 * - firstName
 * - lastName
 * - email
 * - postalCode
 * - city
 * - street
 * - avatar (file)
 * - gender
 * - dateOfBirth
 * - title
 * - phoneNumber
 * - country
 * @param {FormData} data
 */
export async function updateUserData(data) {
    return ApiService.sendForm({
        url: '/users',
        method: 'post',
        data
    })
}
